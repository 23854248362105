import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { isExpired, decodeToken } from "react-jwt";
import { encode } from "js-base64";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface UserDataProps {
  id: string;
  username: string;
  role: number;
  is_admin: boolean;
}

interface ResponseProps extends Response {
  success: boolean;
  token: string;
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  useEffect(() => {
    validateSession();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 5000);
  }, [isError, isSuccess]);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: UserDataProps | null = decodeToken(tokenCoded);
      dataUser && setIsAdmin(dataUser.is_admin);
    }
  }, []);

  const logoutHandler = async () => {
    setIsLoading(true);
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
    setIsLoading(false);
  };

  const loginHandler = async (username: string, password: string) => {
    setIsLoading(true);
    try {
      const passwordEncoded = handleEncodeString(password);
      const data = {
        username,
        password: passwordEncoded,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      };
      const res = await fetch(`${process.env.REACT_APP_DEV_API}/auth`, options);
      const response = await res.json();
      if (response.success) {
        localStorage.setItem("info", response.token);
        navigate(0);
        setIsSuccess(true);
      } else {
        setIsLoading(false);
        setErrorMessage("Not Authorized, please contact the Administrator");
        setIsError(true);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage("Not Authorized, please contact the Administrator");
      setIsError(true);
      throw new Error("Not Authorized, please contact the Administrator");
    }
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler();
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("info");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
    } else {
      return false;
    }
    return true;
  };

  const handleEncodeString = (stringToEncode: string) => {
    return encode(encode(encode(stringToEncode)));
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isSuccess,
        isError,
        isLoading,
        isAdmin,
        errorMessage,
        isHelpModalOpen,
        onOpenHelpModal: (open) => setIsHelpModalOpen(open),
        onLogin: loginHandler,
        onLogout: logoutHandler,
        validateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
